import { Platform } from 'react-native';
import { Mixpanel } from 'mixpanel-react-native';

const MIXPANEL_TOKENS = {
  development: 'af4f1e9bf0f2d0073aa97ae08ca05427',
  production: 'ad0878b0eff342228ac2a9f520b8260a',
};

const ENV = __DEV__ ? 'development' : 'production';
const MIXPANEL_TOKEN = MIXPANEL_TOKENS[ENV];

const trackAutomaticEvents = false;
const useNative = false;
const serverURL = 'https://api.mixpanel.com';

const mixpanel = new Mixpanel(
  MIXPANEL_TOKEN,
  trackAutomaticEvents,
  useNative,
  serverURL,
);

export const initializeMixpanel = async () => {
  try {
    await mixpanel.init();

    mixpanel.registerSuperProperties({
      platform: Platform.OS,
      environment: __DEV__ ? 'development' : 'production',
      deviceType: Platform.OS === 'web' ? 'browser' : 'mobile_app',
      isExpo: true,
    });

    mixpanel.flush();

    return true;
  } catch (error) {
    console.error('🔴 Mixpanel initialization error:', error);
    return false;
  }
};

export const track = async (eventName, properties = {}) => {
  try {
    mixpanel.track(eventName, {
      ...properties,
      timestamp: new Date().toISOString(),
      platform: Platform.OS,
    });

    mixpanel.flush();
    console.log(`✅ Tracked event: ${eventName}`);
  } catch (error) {
    console.error(`🔴 Error tracking event ${eventName}:`, error);
  }
};
