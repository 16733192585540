import { registerRootComponent } from 'expo';
import * as Sentry from '@sentry/react-native';

import App from './App';

const SENTRY_DSN_DEV =
  'https://440c53cb5b614d7c8377d7ef70e70df5@app.glitchtip.com/6181';
const SENTRY_DSN_PROD =
  'https://70ee06ed451d47159313edb47a945ad7@app.glitchtip.com/3419';

Sentry.init({
  dsn: __DEV__ ? SENTRY_DSN_DEV : SENTRY_DSN_PROD,
  enableInExpoDevelopment: true,
  debug: __DEV__ ? true : false,
  environment: __DEV__ ? 'development' : 'production',
  enableAutoSessionTracking: true,
  ignoreErrors: !__DEV__
    ? [
        'AxiosError: Network Error',
        'AxiosError: Request failed with status code 401',
        'AxiosError: Request failed with status code 404',
        /^QuotaExceededError.*/
        
      ]
    : [],
});

const SentryWrappedApp = Sentry.wrap(App);

registerRootComponent(SentryWrappedApp);
