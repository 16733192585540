import { format } from 'date-fns';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { SingleChange } from 'react-native-paper-dates/src/Date/Calendar';
import IconWrapper from './IconWrapper';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';

export const DatePicker: React.FC<{
  date: Date;
  setDate: (date: Date) => void;
  label?: string;
}> = React.memo(function DatePicker({ date, setDate, label }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);
  const confirm: SingleChange = React.useCallback(
    ({ date }) => {
      setIsOpen(false);
      setDate(date as Date);
    },
    [setDate],
  );

  return (
    <>
      <TouchableOpacity onPress={open}>
        <FadeInFromTopAnimation duration={250} delay={200}>
          <IconWrapper icon="calendar">
            <TextInput
              mode="outlined"
              // dense
              label={label || 'Datum'}
              value={format(date, 'd. M. yyyy')}
              autoComplete="off"
              editable={false}
              pointerEvents="none"
              style={styles.input}
            />
          </IconWrapper>
        </FadeInFromTopAnimation>
      </TouchableOpacity>

      <DatePickerModal
        locale="cs"
        mode="single"
        visible={isOpen}
        date={date}
        onDismiss={close}
        onConfirm={confirm}
        saveLabel="Uložit"
        label="Vyberte datum"
      />
    </>
  );
});

const styles = StyleSheet.create({
  input: {
    backgroundColor: 'white',
    marginBottom: 10,
    width: 285,
  },
});
