import { useRef, useCallback, useState, useEffect } from 'react';
import { isWeb } from '../lib/is-web';
import logo from '../../assets/kufr-logo.png';
import { useRecoilValue } from 'recoil';
import { parametersState } from '../store';
import useIsMobile from './use-is-mobile';

type ChartDownloadType = 'class' | 'child';

export const useChartDownload = (type: ChartDownloadType) => {
  const chartRef = useRef<any>(null);
  const [html2canvas, setHtml2canvas] = useState<any>(null);
  const [jsPDF, setJsPDF] = useState<any>(null);
  const parameters = useRecoilValue(parametersState);
  const isMobile = useIsMobile();

  const parameterHeading =
    type === 'class'
      ? parameters.graph_heading_class
      : parameters.graph_heading_child;
  const parameterParagraph =
    type === 'class'
      ? parameters.graph_paragraph_text_class
      : parameters.graph_paragraph_text_child;

  const parameterWeb = 'www.' + parameters.luzanky_web;

  useEffect(() => {
    if (isWeb) {
      try {
        const html2canvasModule = require('html2canvas');
        const jsPDFModule = require('jspdf');
        setHtml2canvas(() => html2canvasModule.default || html2canvasModule);
        setJsPDF(() => jsPDFModule.default || jsPDFModule);
      } catch (err) {
        console.error('Failed to load modules:', err);
      }
    }
  }, []);

  const downloadPDF = useCallback(
    async (
      schoolName: string,
      child?: string,
      classroom?: string,
      childAge?: string,
      percentageAverage?: number,
    ) => {
      if (!isWeb) {
        console.log('PDF download is only available on web');
        return;
      }

      if (!html2canvas || !jsPDF) {
        console.error('html2canvas or jsPDF not loaded');
        return;
      }

      const chart = chartRef.current;
      if (!chart) {
        console.error('Chart ref is null');
        return;
      }

      try {
        console.log('Starting to generate canvas');
        const canvas = await html2canvas(chart, {
          scale: 2,
          useCORS: true,
          logging: true,
        });
        console.log('Canvas generated successfully');

        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = 297; // A4 height in mm
        const aspectRatio = canvas.height / canvas.width;

        const margin = 10;
        const imageWidth = isMobile
          ? (pdfWidth - 2 * margin) * 0.47
          : pdfWidth - 2 * margin;
        const imageHeight = imageWidth * aspectRatio;

        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
        });

        pdf.addFont(
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Regular.ttf',
          'Roboto',
          'normal',
        );
        pdf.setFont('Roboto');

        // Helper function for multiline text
        const addMultiLineText = (
          text: string,
          x: number,
          y: number,
          maxWidth: number,
          fontSize: number,
          lineHeight: number,
          align: 'left' | 'center' | 'right' = 'left',
        ) => {
          pdf.setFontSize(fontSize);
          const lines = pdf.splitTextToSize(text, maxWidth);
          lines.forEach((line: string, index: number) => {
            const xPos =
              align === 'center'
                ? x + maxWidth / 2
                : align === 'right'
                  ? x + maxWidth
                  : x;
            pdf.text(line, xPos, y + index * lineHeight, { align });
          });
          return lines.length * lineHeight;
        };

        // Primary color
        const darkRed = [161, 27, 27];

        // Add title
        let yPosition = 20;
        const fallbackHeading =
          'Grafické znázornění vyhodnocení vývojových škál pedagogické diagnostiky dítěte předškolního věku';
        yPosition += addMultiLineText(
          parameterHeading ?? fallbackHeading,
          margin,
          yPosition,
          pdfWidth - 2 * margin,
          14,
          7,
          'center',
        );

        // Add description
        yPosition += 5;
        const fallbackDescription =
          'Stažený soubor je grafickým znázorněním vyhodnocení vývojových škál vyplněného Záznamového archu prostřednictvím aplikace Klokanův kufr, kde je uvedeno hodnocení dítěte v jednotlivých oblastech pedagogické diagnostiky s uvedením "zvládá, nezvládá, zvládá s dopomocí" dle publikace Diagnostika dítěte předškolního věku: Co by mělo dítě umět ve věku od 3 do 6 let. Bednářová, Šmardová, Edika, 2013.';
        yPosition += addMultiLineText(
          parameterParagraph ?? fallbackDescription,
          margin,
          yPosition,
          pdfWidth - 2 * margin,
          10,
          5,
        );

        yPosition += 5;

        // Create a row for child info
        const childInfoWidth = (pdfWidth - 2 * margin) / 2;
        let leftColY = yPosition;
        let rightColY = yPosition;

        // Add child name
        if (child) {
          pdf.setTextColor(darkRed[0], darkRed[1], darkRed[2]);
          leftColY += addMultiLineText(
            child,
            margin,
            leftColY,
            childInfoWidth,
            10,
            5,
            'left',
          );
        }
        // Add classroom
        if (classroom) {
          pdf.setTextColor(darkRed[0], darkRed[1], darkRed[2]);
          leftColY += addMultiLineText(
            `Třída: ${classroom}`,
            margin,
            leftColY,
            childInfoWidth,
            10,
            5,
            'left',
          );
        }

        // Add age information
        if (childAge) {
          pdf.setTextColor(darkRed[0], darkRed[1], darkRed[2]);
          rightColY += addMultiLineText(
            type === 'class' ? `Průměrný věk: ${childAge}` : `Věk: ${childAge}`,
            margin + childInfoWidth,
            rightColY,
            childInfoWidth,
            10,
            5,
            'right',
          );
        }

        // Add percentage of filled tasks
        if (percentageAverage !== undefined) {
          pdf.setTextColor(darkRed[0], darkRed[1], darkRed[2]);
          rightColY += addMultiLineText(
            `Vyplněno úkolů: ${percentageAverage.toFixed(1)}%`,
            margin + childInfoWidth,
            rightColY,
            childInfoWidth,
            10,
            5,
            'right',
          );
        }

        // Reset text color
        pdf.setTextColor(0, 0, 0);

        // Add graph
        yPosition = Math.max(leftColY, rightColY) + 5;

        const graphX = isMobile
          ? margin + (pdfWidth - 2 * margin - imageWidth) / 2
          : margin;
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          graphX,
          yPosition,
          imageWidth,
          imageHeight,
        );

        // Footer
        const footerGap = 2;
        yPosition += imageHeight + (isMobile ? 10 : 20);

        // Add footer text (left-aligned)
        pdf.setFontSize(10);
        yPosition += addMultiLineText(
          'Autor aplikace: Lužánky – středisko volného času Brno, příspěvková organizace',
          margin,
          yPosition,
          pdfWidth - 2 * margin,
          10,
          5,
          'left',
        );
        yPosition += footerGap;
        yPosition += addMultiLineText(
          `Vyplněná data: ${schoolName}`,
          margin,
          yPosition,
          pdfWidth - 2 * margin,
          10,
          5,
          'left',
        );

        // Add contract information
        yPosition += 10;
        const contractInfo =
          'Mateřská škola uzavřela Smlouvu o užívání aplikace Klokanův kufr, jejíž součástí je Smlouva o zpracování osobních údajů podle GDPR. Mateřská škola je správcem osobních údajů, které získává za účelem plnění svých zákonných povinností dle zákona č. 561/2004 Sb., o předškolním, základním, středním, vyšším odborném a jiném vzdělávání (školský zákon). Tento dokument obsahuje důvěrné údaje z pedagogické diagnostiky a je určen výhradně pro zákonné zástupce dítěte. Jakékoliv další šíření nebo použití údajů je zakázáno. Mateřská škola se zavazuje plnit veškeré své zákonné a smluvní povinnosti jakožto správce osobních údajů.';
        yPosition += addMultiLineText(
          contractInfo,
          margin,
          yPosition,
          pdfWidth - 2 * margin,
          8,
          4,
        );

        // Add logo and website at the bottom
        const bottomMargin = 15;
        const logoWidth = 40;
        const logoHeight = logoWidth * (13.33 / 40);

        // Logo
        pdf.addImage(
          logo,
          'PNG',
          (pdfWidth - logoWidth) / 2,
          pdfHeight - bottomMargin - logoHeight - 5,
          logoWidth,
          logoHeight,
        );

        // Website
        pdf.setFontSize(8);
        addMultiLineText(
          parameterWeb ?? 'www.klokanovyskolky.cz',
          margin,
          pdfHeight - bottomMargin,
          pdfWidth - 2 * margin,
          8,
          4,
          'center',
        );

        // Generation date
        const currentDate = new Date().toLocaleDateString('cs-CZ', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        pdf.setFontSize(8);
        pdf.text(
          `Datum vytvoření: ${currentDate}`,
          pdfWidth - margin,
          pdfHeight - bottomMargin + 10,
          { align: 'right' },
        );

        console.log('Saving PDF');
        pdf.save(
          `${child ? child : classroom} - Klokanův kufr graf - datum ${currentDate}.pdf`,
        );
        console.log('PDF saved successfully');
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    },
    [
      html2canvas,
      jsPDF,
      isMobile,
      parameterHeading,
      parameterParagraph,
      parameterWeb,
    ],
  );

  return { chartRef, downloadPDF };
};
