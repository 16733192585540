import { atom, selector, selectorFamily } from 'recoil';
import { Components } from './server';
import { icons } from './components/icons';
import { pickByIds, groupByKey } from './lib/pick-ids';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isWeb } from './lib/is-web';

export interface PersistStorage {
  setItem(key: string, value: string): void | Promise<void>;
  removeItem(key: string): void | Promise<void>;
  getItem(key: string): null | string | Promise<string | null>;
}
const asyncLocalStorage: PersistStorage = {
  async setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  },
  async getItem(key: string) {
    return localStorage.getItem(key);
  },
  async removeItem(key: string) {
    return localStorage.removeItem(key);
  },
};

const CHUNK_SIZE = 500 * 1024;
const MAX_CHUNKS = 20;

// add chunked storage for assessments
const chunkedAssessmentsStorage = {
  async setItem(key: string, value: string) {
    const chunks = [];
    for (let i = 0; i < value.length; i += CHUNK_SIZE) {
      chunks.push(value.slice(i, i + CHUNK_SIZE));
    }
    for (let i = 0; i < chunks.length; i++) {
      localStorage.setItem(`${key}-chunk-${i}`, chunks[i] || '0');
    }
    localStorage.setItem(`${key}-chunks`, chunks.length.toString());
    for (let i = chunks.length; i < MAX_CHUNKS; i++) {
      localStorage.removeItem(`${key}-chunk-${i}`);
    }
  },
  async getItem(key: string) {
    const chunkCount = parseInt(
      localStorage.getItem(`${key}-chunks`) || '0',
      10,
    );
    if (chunkCount === 0) return null;
    let result = '';
    for (let i = 0; i < chunkCount; i++) {
      result += localStorage.getItem(`${key}-chunk-${i}`) || '';
    }
    return result;
  },
  async removeItem(key: string) {
    const chunkCount = parseInt(
      localStorage.getItem(`${key}-chunks`) || '0',
      10,
    );
    for (let i = 0; i < chunkCount; i++) {
      localStorage.removeItem(`${key}-chunk-${i}`);
    }
    localStorage.removeItem(`${key}-chunks`);
  },
};

const assessmentsStorageEffect =
  (key: string) =>
  ({
    setSelf,
    onSet,
    trigger,
  }: {
    setSelf: Function;
    onSet: Function;
    trigger: string;
  }) => {
    const storage = isWeb ? chunkedAssessmentsStorage : AsyncStorage;

    const loadPersisted = async () => {
      try {
        const savedValue = await storage.getItem(key);
        if (savedValue !== null) {
          setSelf(JSON.parse(savedValue));
        }
      } catch (error) {
        console.error('Error loading persisted assessments data:', error);
      }
    };

    if (trigger === 'get') {
      loadPersisted();
    }

    onSet((newValue: object | undefined, _: any, isReset: boolean) => {
      if (isReset || newValue === undefined) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

const asyncStorageEffect =
  (key: string) =>
  ({
    setSelf,
    onSet,
    trigger,
  }: {
    setSelf: Function;
    onSet: Function;
    trigger: string;
  }) => {
    const storage: PersistStorage = isWeb ? asyncLocalStorage : AsyncStorage;
    const loadPersisted = async () => {
      const savedValue = await storage.getItem(key);

      if (savedValue !== null) {
        setSelf(JSON.parse(savedValue));
      }
    };

    if (trigger === 'get') {
      loadPersisted();
    }

    onSet((newValue: object | undefined, _: any, isReset: boolean) => {
      if (isReset || newValue === undefined) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const persistKey = 'persist-v2.1.0';

export const assessmentsState = atom<{
  [key: number]: Components.Schemas.Assessment;
}>({
  key: 'assessments',
  effects_UNSTABLE: [assessmentsStorageEffect(`${persistKey}-assessments`)],
  default: {},
});

export const categoriesState = atom<{
  [key: number]: Components.Schemas.Category;
}>({
  key: 'categories',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-categories`)],
  default: {},
});

export const subcategoriesState = atom<{
  [key: number]: Components.Schemas.Subcategory;
}>({
  key: 'subcategories',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-subcategories`)],
  default: {},
});

export type TaskType = Components.Schemas.Task & {
  realAgeFrom: number;
  realAgeTo: number;
  ageString?: string;
};
export const tasksState = atom<{ [key: number]: TaskType }>({
  key: 'tasks',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-tasks`)],
  default: {},
});

export type AssessmentTypeType = Components.Schemas.AssessmentType & {
  optionsScore: { [id: number]: number };
};

export const assessmentTypesState = atom<{ [key: number]: AssessmentTypeType }>(
  {
    key: 'assessmentTypes',
    effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-assessmentTypes`)],
    default: {},
  },
);

export const schoolState = atom<Components.Schemas.School | undefined>({
  key: 'school',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-school`)],
  default: undefined,
});

export const usersState = atom<{ [key: number]: Components.Schemas.User }>({
  key: 'users',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-users`)],
  default: {},
});

export const classesState = atom<{
  [key: number]: Components.Schemas.Classroom;
}>({
  key: 'classes',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-classes`)],
  default: {},
});

export type ChildType = Components.Schemas.Child & {
  ageString: string;
  ageShortString: string;
  ageNumber: number;
  shortName: string;
};
export const childrenState = atom<{ [key: number]: ChildType }>({
  key: 'children',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-children`)],
  default: {},
});

export const allClassNotesState = atom<{
  [key: number]: Components.Schemas.ClassroomNote;
}>({
  key: 'allClassNotes',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-allClassNotes`)],
  default: {},
});

export const allChildNotesState = atom<{
  [key: number]: Components.Schemas.ChildNote;
}>({
  key: 'allChildNotes',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-allChildNotes`)],
  default: {},
});

export const parametersState = atom<{ [key: string]: string }>({
  key: 'parameters',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-parameters`)],
  default: {},
});

export const appReadyState = atom<boolean>({
  key: 'appReady',
  effects_UNSTABLE: [asyncStorageEffect(`${persistKey}-appReady`)],
  default: false,
});

export const dataReadyState = selector({
  key: 'dataReady',
  get: ({ get }) =>
    [
      get(categoriesState),
      get(subcategoriesState),
      get(tasksState),
      get(assessmentTypesState),
      get(schoolState),
      get(usersState),
      get(parametersState),
    ].every((x) => Object.keys(x || {}).length > 0),
});

export type SubcategorySections = (Components.Schemas.Subcategory & {
  data: TaskType[];
})[];
export type TaskSections = (TaskType & {
  assessments: Components.Schemas.Assessment[];
})[];
export type TaskMap = {
  [category: number]: { [subcategory: number]: TaskType[] };
};

type AgeSections<T> = {
  forYounger: T;
  appropriate: T;
  forOlder: T;
};

export const ageRelevantTasksState = selectorFamily({
  key: 'ageRelevantTasks',
  get:
    (age: number) =>
    ({ get }) => {
      const tasks = get(tasksState);
      const subcategories = get(subcategoriesState);

      const sections: AgeSections<TaskMap> = {
        forYounger: {},
        appropriate: {},
        forOlder: {},
      };

      const pushToMap = (map: TaskMap, task: TaskType) => {
        const sub = subcategories[task.subcategory!];
        if (!sub) {
          return;
        }
        const catId = sub.parent_category;
        if (map[catId] === undefined) {
          map[catId] = {};
        }
        if (map[catId]![sub.id] === undefined) {
          map[catId]![sub.id] = [];
        }
        map[catId]![sub.id]!.push(task);
      };

      Object.values(tasks).forEach((task) => {
        if (age < task.realAgeFrom) {
          pushToMap(sections.forOlder, task);
        } else if (age > task.realAgeTo) {
          pushToMap(sections.forYounger, task);
        } else {
          pushToMap(sections.appropriate, task);
        }
      });

      return sections;
    },
});

export const ageRelevantCategoryState = selectorFamily({
  key: 'ageRelevantCategory',
  get:
    ({ categoryId, childId }: { categoryId: number; childId: number }) =>
    ({ get }) => {
      const category = get(categoryState(categoryId));
      const subcategories = get(subcategoriesState);
      const child = get(childState(childId));
      const tasks = get(ageRelevantTasksState(child?.ageNumber || 0));

      const sections: AgeSections<SubcategorySections> = {
        forYounger: [],
        appropriate: [],
        forOlder: [],
      };

      category?.subcategories.forEach((subcategoryId) => {
        const sub = subcategories[subcategoryId]!;
        sections.forYounger.push({
          ...sub,
          data: tasks.forYounger[categoryId]?.[subcategoryId] || [],
        });
        sections.appropriate.push({
          ...sub,
          data: tasks.appropriate[categoryId]?.[subcategoryId] || [],
        });
        sections.forOlder.push({
          ...sub,
          data: tasks.forOlder[categoryId]?.[subcategoryId] || [],
        });
      });

      return sections;
    },
});

export const tasksBySubcategoryState = selectorFamily<
  SubcategorySections,
  number
>({
  key: 'categoryTasks',
  get:
    (id: number) =>
    ({ get }) => {
      const category = get(categoryState(id))!;
      if (!category) {
        return [];
      }

      const subcategories = get(subcategoriesState);
      const tasks = get(tasksState);

      const result: { [subcategoryId: number]: TaskType[] } = {};
      Object.values(tasks).forEach((task) => {
        const subId = task.subcategory!;
        if (result[subId] === undefined) {
          result[subId] = [];
        }
        result[subId]!.push(task);
      });

      return category.subcategories.map((subcategoryId) => {
        const subcategory = subcategories[subcategoryId]!;
        return { ...subcategory, data: result[subcategoryId] || [] };
      });
    },
});

export const categoryOrderState = selector({
  key: 'categoryOrder',
  get: ({ get }) => {
    let ordered: number[] = [];
    const categories: Components.Schemas.Category[] = [
      ...Object.values(get(categoriesState)),
    ];
    Object.keys(icons).forEach((label) => {
      const i = categories.findIndex((x) => x.label === label);
      if (i > -1) {
        const cat = categories.splice(i, 1);
        ordered = ordered.concat(cat[0]!.id);
      }
    });
    return ordered.concat(...categories.map((x) => x.id));
  },
});

export const categoryState = selectorFamily({
  key: 'category',
  get:
    (id: number) =>
    ({ get }) =>
      get(categoriesState)[id],
});

export const categorySubcategoriesState = selectorFamily({
  key: 'categorySubcategories',
  get:
    (id: number) =>
    ({ get }) => {
      const category = get(categoryState(id));
      const subcategories = get(subcategoriesState);
      return pickByIds(subcategories, category?.subcategories);
    },
});

export const subcategoryState = selectorFamily({
  key: 'subcategory',
  get:
    (id: number) =>
    ({ get }) =>
      get(subcategoriesState)[id],
});

export const taskState = selectorFamily({
  key: 'task',
  get:
    (id: number) =>
    ({ get }) =>
      get(tasksState)[id],
});

export const assessmentState = selectorFamily({
  key: 'assessment',
  get:
    (id: number) =>
    ({ get }) =>
      get(assessmentsState)[id],
});

export const assessmentTypeState = selectorFamily({
  key: 'assessmentType',
  get:
    (id: number) =>
    ({ get }) =>
      get(assessmentTypesState)[id],
});

export const childState = selectorFamily({
  key: 'child',
  get:
    (id: number) =>
    ({ get }) =>
      get(childrenState)[id],
});

export const userState = selectorFamily({
  key: 'user',
  get:
    (id: number) =>
    ({ get }) =>
      get(usersState)[id],
});

export const latestAssessmentsState = selector({
  key: 'latestAssessments',
  get: ({ get }) =>
    [...Object.values(get(assessmentsState))].sort((x, y) =>
      x.id > y.id ? -1 : 1,
    ),
});

export const childNotesByChildState = selector({
  key: 'childNotesByChild',
  get: ({ get }) => groupByKey(get(allChildNotesState), 'child'),
});

export const classNotesByClassState = selector({
  key: 'classNotesByClass',
  get: ({ get }) => groupByKey(get(allClassNotesState), 'classroom'),
});

export const childrenByClassState = selector({
  key: 'childrenByClass',
  get: ({ get }) => groupByKey(get(childrenState), 'classroom'),
});

export const assessmentsByChildState = selector({
  key: 'assessmentsByChild',
  get: ({ get }) => groupByKey(get(assessmentsState), 'child'),
});

export const childAssessmentsByTaskState = selectorFamily({
  key: 'childAssessmentsByTask',
  get:
    (id: number) =>
    ({ get }) =>
      groupByKey(get(assessmentsByChildState)[id] || [], 'task'),
});

export const childNotesState = selectorFamily({
  key: 'childNotes',
  get:
    (id: number) =>
    ({ get }) => {
      const notes = [...(get(childNotesByChildState)[id] || [])];
      notes.sort(
        (x, y) =>
          -0.5 + (y.created_at || '')?.localeCompare(x.created_at || ''),
      );
      return notes;
    },
});

export const classState = selectorFamily({
  key: 'class',
  get:
    (id: number) =>
    ({ get }) =>
      get(classesState)[id],
});

export const classNotesState = selectorFamily({
  key: 'classNotes',
  get:
    (id: number) =>
    ({ get }) => {
      const notes = [...(get(classNotesByClassState)[id] || [])];
      notes.sort(
        (x, y) =>
          -0.5 + (y.created_at || '')?.localeCompare(x.created_at || ''),
      );
      return notes;
    },
});

export const classChildrenState = selectorFamily({
  key: 'classChildren',
  get:
    (id: number) =>
    ({ get }) =>
      get(childrenByClassState)[id] || [],
});

export const classAssessmentsByTaskState = selectorFamily({
  key: 'classAssessmentsByTask',
  get:
    (id: number) =>
    ({ get }) => {
      const children = get(classChildrenState(id));
      const assessments = get(assessmentsByChildState);
      const classAssessments = children
        .reduce(
          (xs, c) => xs.concat(assessments[c.id] || []),
          [] as Components.Schemas.Assessment[],
        )
        .sort();
      return groupByKey(classAssessments, 'task');
    },
});

export const categoryTreeState = selector({
  key: 'categoryTree',
  get: ({ get }) => {
    const tasks = get(tasksState);
    const categories = get(categoriesState);
    const subcategories = get(subcategoriesState);

    const subcategoryMap = Object.fromEntries(
      Object.values(subcategories).map((subcategory) => {
        const taskIds = subcategory.tasks;
        const taskMap = Object.fromEntries(
          taskIds.map((id) => [id, tasks[id]]),
        );
        return [subcategory.id, { ...subcategory, tasks: taskMap }];
      }),
    );

    return Object.fromEntries(
      Object.values(categories).map((category) => {
        const subcategories = Object.fromEntries(
          category.subcategories.map((subcategoryId) => {
            return [subcategoryId, subcategoryMap[subcategoryId]];
          }),
        );
        return [category.id, { ...category, subcategories }];
      }),
    );
  },
});

export type SubcategoryStats = {
  fillRate: number;
  score: number;
  nonScoreAble?: boolean;
};

export type CategoryStats = {
  averageFillRate: number;
  averageScore: number;
};

const calculateChildSubcategoryStats = (
  sections: AgeSections<TaskType[]>,
  assessmentTypes: { [key: number]: AssessmentTypeType },
  allAssessments: { [key: number]: Components.Schemas.Assessment[] },
  tasks: { [key: number]: TaskType },
) => {
  const exampleTask =
    sections.forYounger[0] || sections.appropriate[0] || sections.forOlder[0];
  if (!exampleTask) {
    return { fillRate: NaN, score: NaN };
  }
  const assessmentType =
    assessmentTypes[tasks[exampleTask.id]?.assessment_type || -1]!;
  const scoreDivisor = Math.max((assessmentType.options?.length || 1) - 1, 1);

  const statsAppropriate = { score: 0, filledOut: 0 };
  let shouldBeFilledOut = 0;
  let nonScoreAble = false;

  // Original excluded assessment types
  const excludedAssessmentTypes = new Set([15, 16, 17, 18]);

  // Tasks for subcategory 52
  const excludedSubcategoryTasks = {
    subcategoryId: 52,
    taskIds: new Set([119, 120, 121, 122, 123, 124, 125, 126]),
  };
  sections.appropriate.forEach((task) => {
    if (
      excludedAssessmentTypes.has(task.assessment_type) ||
      (task.subcategory === excludedSubcategoryTasks.subcategoryId &&
        excludedSubcategoryTasks.taskIds.has(task.id))
    ) {
      nonScoreAble = true;
      return;
    }

    const assessments = allAssessments[task.id] || [];
    if (assessments.length > 0) {
      const latest = assessments.reduce((x, y) =>
        x.date_of_assessment > y.date_of_assessment ? x : y,
      );
      if (!task.difficulty) {
        if (task.realAgeFrom !== 0 && task.realAgeTo !== 9) {
          statsAppropriate.score +=
            assessmentType.optionsScore[latest.option] || 0;
        }
        statsAppropriate.filledOut += 1;
      }
    }
    if (!task.difficulty) {
      shouldBeFilledOut += 1;
    }
  });

  const statsOlder = { score: 0, filledOut: 0 };
  sections.forOlder.forEach((task) => {
    if (
      excludedAssessmentTypes.has(task.assessment_type) ||
      (task.subcategory === excludedSubcategoryTasks.subcategoryId &&
        excludedSubcategoryTasks.taskIds.has(task.id))
    ) {
      nonScoreAble = true;
      return;
    }

    const assessments = allAssessments[task.id] || [];
    if (assessments.length > 0) {
      const latest = assessments.reduce((x, y) =>
        x.date_of_assessment > y.date_of_assessment ? x : y,
      );
      if (!task.difficulty) {
        if (task.realAgeFrom !== 0 && task.realAgeTo !== 9) {
          statsOlder.score += assessmentType.optionsScore[latest.option] || 0;
        }
        statsOlder.filledOut += 1;
      }
    }
  });
  const scoreAppropriate =
    statsAppropriate.filledOut === 0
      ? 1
      : statsAppropriate.score / scoreDivisor / statsAppropriate.filledOut;
  const scoreOlder =
    statsOlder.score / scoreDivisor / (statsOlder.filledOut || 1);
  const fillRate =
    sections.appropriate.length === 0
      ? 1
      : statsAppropriate.filledOut / shouldBeFilledOut;

  return {
    fillRate: !Number.isNaN(fillRate) ? fillRate : 1,
    score: !Number.isNaN(scoreAppropriate + scoreOlder)
      ? scoreAppropriate + scoreOlder
      : 1,
    nonScoreAble,
  };
};

export const calculateChildCategoryStats = (
  category: Components.Schemas.Category,
  assessmentTypes: { [key: number]: AssessmentTypeType },
  allAssessments: { [key: number]: Components.Schemas.Assessment[] },
  allTasks: { [key: number]: TaskType },
  tasks: AgeSections<TaskMap>,
): CategoryStats & {
  subcategoryStats: { [id: number]: SubcategoryStats };
} => {
  const subcategoryStats: { [id: number]: SubcategoryStats } = {};
  category.subcategories.forEach((subcategoryId) => {
    const sections = {
      forYounger: tasks.forYounger[category.id]?.[subcategoryId] || [],
      appropriate: tasks.appropriate[category.id]?.[subcategoryId] || [],
      forOlder: tasks.forOlder[category.id]?.[subcategoryId] || [],
    };

    subcategoryStats[subcategoryId] = calculateChildSubcategoryStats(
      sections,
      assessmentTypes,
      allAssessments,
      allTasks,
    );
  });

  const stats = Object.values(subcategoryStats).filter(
    (x) => !Number.isNaN(x.fillRate) && !Number.isNaN(x.score),
  );

  const averageFillRate =
    stats.reduce((n, sub) => n + sub.fillRate, 0) / stats.length;

  const averageScore =
    stats.reduce((n, sub) => n + sub.score, 0) / stats.length;

  return { subcategoryStats, averageFillRate, averageScore };
};

export const childTotalStatsState = selectorFamily({
  key: 'childTotalStats',
  get:
    ({ childId, date }: { childId: number; date?: Date }) =>
    async ({ get }) => {
      const currentDate = date || new Date();
      const child = get(childState(childId));
      const tasks = get(ageRelevantTasksState(child?.ageNumber || 0));
      const allAssessments = get(childAssessmentsByTaskState(childId));
      const assessmentTypes = get(assessmentTypesState);
      const allTasks = get(tasksState);

      // Filter assessments by date
      const filteredAssessments = Object.fromEntries(
        Object.entries(allAssessments).map(([taskId, assessments]) => [
          taskId,
          assessments.filter(
            (assessment) =>
              new Date(assessment.date_of_assessment) <= currentDate,
          ),
        ]),
      );

      const categoryStats: {
        [id: string]: CategoryStats & {
          subcategoryStats: { [id: number]: SubcategoryStats };
        };
      } = {};
      Object.values(get(categoriesState)).forEach((category) => {
        categoryStats[category.id] = calculateChildCategoryStats(
          category,
          assessmentTypes,
          filteredAssessments,
          allTasks,
          tasks,
        );
      });

      const stats = Object.values(categoryStats);
      const averageFillRate =
        stats.reduce((n, sub) => n + sub.averageFillRate, 0) / stats.length;

      const averageScore =
        stats.reduce((n, sub) => n + sub.averageScore, 0) / stats.length;

      return { categoryStats, averageFillRate, averageScore };
    },
});

type NotFilledOutChildren = ChildType & {
  averageFillRateCategory: number;
};

export const classStatsState = selectorFamily({
  key: 'classStats',
  get:
    ({ classId, date }: { classId: number; date?: Date }) =>
    async ({ get }) => {
      const currentDate = date || new Date();
      const categories = get(categoriesState);
      const allChildren = get(childrenState);
      const classroom = get(classState(classId));
      const params = get(parametersState);
      const children = classroom?.children || [];
      const successRateCutoff = parseFloat(
        (params.app_threshold_success_rate || '0.6').replace(',', '.'),
      );
      const fillRateCutoff = parseFloat(
        (params.app_threshold_fill_rate || '1').replace(',', '.'),
      );

      const assessmentTypes = get(assessmentTypesState);
      const allTasks = get(tasksState);

      const categoryStats: {
        [id: number]: CategoryStats & {
          laggingChildren: ChildType[];
          notFilledOutChildren: NotFilledOutChildren[];
        };
      } = {};
      Object.values(categories).forEach((category) => {
        const laggingChildren: ChildType[] = [];
        const notFilledOutChildren: NotFilledOutChildren[] = [];
        let totalFillRate = 0;
        let totalScore = 0;

        children.forEach((childId) => {
          const child = allChildren[childId];
          if (!child) {
            return;
          }

          const tasks = get(ageRelevantTasksState(child?.ageNumber || 0));
          const allAssessments = get(childAssessmentsByTaskState(childId));

          // Filter assessments by date
          const filteredAssessments = Object.fromEntries(
            Object.entries(allAssessments).map(([taskId, assessments]) => [
              taskId,
              assessments.filter(
                (assessment) =>
                  new Date(assessment.date_of_assessment) <= currentDate,
              ),
            ]),
          );
          const stats = calculateChildCategoryStats(
            category,
            assessmentTypes,
            filteredAssessments,
            allTasks,
            tasks,
          );

          const averageFillRateCategory =
            category.subcategories.reduce((acc, subId) => {
              const subStats = stats.subcategoryStats[subId];
              return subStats ? acc + subStats.fillRate : acc;
            }, 0) / category.subcategories.length || 0;

          totalFillRate += stats.averageFillRate;
          totalScore += stats.averageScore;

          if (stats.averageScore < successRateCutoff) {
            laggingChildren.push(child);
          }
          if (averageFillRateCategory < fillRateCutoff) {
            notFilledOutChildren?.push({
              ...child,
              averageFillRateCategory,
            });
          }
        });

        const averageFillRate = totalFillRate / children.length;
        const averageScore = totalScore / children.length;

        categoryStats[category.id] = {
          averageFillRate,
          averageScore,
          laggingChildren,
          notFilledOutChildren,
        };
      });

      const totalFillRate = Object.values(categoryStats).reduce(
        (x, y) => x + y.averageFillRate,
        0,
      );

      const totalAge = children.reduce(
        (n, childId) => n + (allChildren[childId]?.ageNumber || 0),
        0,
      );
      return {
        averageAge: totalAge / (children.length || 1),
        averageFillRate:
          totalFillRate / (Object.values(categoryStats).length || 1),
        categoryStats,
      };
    },
});
