import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { appReadyState, dataReadyState } from '../store';

type LoadingStateOptions = {
  delayedMessageTime?: number;
  reconnectButtonTime?: number;
};

export const useLoadingState = (options = {}) => {
  const { delayedMessageTime = 6000, reconnectButtonTime = 15000 } =
    options as LoadingStateOptions;

  const appReady = useRecoilValue(appReadyState);
  const dataReady = useRecoilValue(dataReadyState);

  const [showDelayedMessage, setShowDelayedMessage] = useState(false);
  const [showReconnectButton, setShowReconnectButton] = useState(false);

  useEffect(() => {
    const delayedMessageTimer = setTimeout(() => {
      if (!appReady || !dataReady) {
        setShowDelayedMessage(true);
      }
    }, delayedMessageTime);

    const reconnectButtonTimer = setTimeout(() => {
      if (!appReady || !dataReady) {
        setShowReconnectButton(true);
      }
    }, reconnectButtonTime);

    return () => {
      clearTimeout(delayedMessageTimer);
      clearTimeout(reconnectButtonTimer);
    };
  }, [appReady, dataReady, delayedMessageTime, reconnectButtonTime]);

  const resetLoadingState = () => {
    setShowDelayedMessage(false);
    setShowReconnectButton(false);
  };

  return {
    isLoading: !appReady || !dataReady,
    showDelayedMessage,
    showReconnectButton,
    resetLoadingState,
  };
};
