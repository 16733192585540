import React, { useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { icons } from '../icons';
import * as shape from 'd3-shape';
import * as scale from 'd3-scale';
import * as array from 'd3-array';
import Svg, { Line, Path, Rect } from 'react-native-svg';
import { CategoryStats, schoolState } from '../../store';
import CategoryIconsChart from './CategoryIconsChart';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import { useChartDownload } from '../../hooks/use-chart-download';
import { isWeb } from '../../lib/is-web';
import { theme } from '../../theme';
import { useRecoilValue } from 'recoil';

import Animated, {
  useAnimatedProps,
  useSharedValue,
  withTiming,
  Easing,
} from 'react-native-reanimated';

const AnimatedPath = Animated.createAnimatedComponent(Path);

interface CategoryChartProps {
  categoryOrder: number[];
  categoryStats: { [id: number]: CategoryStats };
  onCategoryPress: (categoryId: number) => void;
  childName?: string;
  percentAverage?: number;
  class?: string;
  childAge?: string;
  type: 'class' | 'child';
}

export const CategoryChart = React.memo(function CategoryChart({
  categoryOrder,
  onCategoryPress,
  categoryStats,
  childName,
  percentAverage,
  childAge,
  class: classRoom,
  type,
}: CategoryChartProps) {
  const { chartRef, downloadPDF } = useChartDownload(type);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const { isDesktop } = useDesktopStyles();
  const school = useRecoilValue(schoolState);

  const data = [1];

  const [forceFullOpacity, setForceFullOpacity] = useState(false);
  const animatedOpacity = useSharedValue(0);

  categoryOrder.forEach((categoryId) => {
    const isScoreZero = categoryStats[categoryId]?.averageScore === 0;
    const isAverateFillRate = categoryStats[categoryId]?.averageFillRate !== 0;

    if (isAverateFillRate && isScoreZero) {
      data.push(0);
      data.push(1);
      return;
    }

    const rawScore = categoryStats[categoryId]?.averageScore || 1;
    data.push(rawScore);
    data.push(1);
  });

  const mappedData = data.map(
    (item, index) => [index, item] as [number, number],
  );
  const yExtent = array.extent(mappedData.map((item) => item[0]));

  const { width: windowWidth } = useWindowDimensions();

  const customWidth = showLabels ? 555 : 700;

  const width = isDesktop ? customWidth : windowWidth - 75;

  const height = 395;

  const y = scale
    .scaleLinear()
    .domain([yExtent[0]!, yExtent[1]!])
    .range([2, height - 2])
    .clamp(true);
  const x = scale
    .scaleLinear()
    .domain([0, 2])
    .range([5, width - 5])
    .clamp(true);

  const area = shape
    .area()
    .x((d) => x(d[1]))
    .y1((d) => y(d[0]))
    .curve(shape.curveCatmullRom)(mappedData);

  const line = shape
    .line()
    .x((d) => x(d[1]))
    .y((d) => y(d[0]))
    .curve(shape.curveCatmullRom)(mappedData);

  React.useEffect(() => {
    if (!forceFullOpacity) {
      animatedOpacity.value = 0;
      animatedOpacity.value = withTiming(1, {
        duration: 500,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });
    }
  }, [categoryStats, width, height, forceFullOpacity]);

  const animatedAreaProps = useAnimatedProps(() => ({
    opacity: forceFullOpacity ? 1 : animatedOpacity.value,
  }));

  const animatedLineProps = useAnimatedProps(() => ({
    opacity: forceFullOpacity ? 1 : animatedOpacity.value,
  }));

  const handleDownload = async () => {
    setIsDownloading(true);
    setShowLabels(true);
    setForceFullOpacity(true);

    requestAnimationFrame(async () => {
      try {
        await downloadPDF(
          school?.name || '',
          childName || '',
          classRoom || '',
          childAge || '',
          percentAverage,
        );
      } catch (error) {
        console.error('Error downloading PDF:', error);
      } finally {
        setShowLabels(false);
        setIsDownloading(false);
        setForceFullOpacity(false);
      }
    });
  };

  return (
    <>
      <View
        ref={chartRef as any}
        style={{
          height: 400 + 45,
          position: 'relative',
          marginBottom: showLabels ? 85 : 0,
          padding: isDesktop ? 6 : 0,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            marginLeft: showLabels && isDesktop ? 115 : 35,
            justifyContent: 'space-around',
          }}
        >
          <Text style={styles.axis}>Potřeba{'\n'}stimulace</Text>
          <Text style={styles.axis}>Odpovídá{'\n'}věku</Text>
          <Text style={styles.axis}>Zvýšit{'\n'}obtížnost</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <CategoryIconsChart {...{ onCategoryPress, icons, showLabels }} />

          <Svg style={{ width, height }}>
            <Rect
              x={x(2 / 3)}
              width={x(4 / 3) - x(2 / 3)}
              y={2}
              height={height - 4}
              fill="rgba(0, 0, 0, 0.075)"
            />
            {[0, 2 / 3, 4 / 3, 2].map((tick, idx) => (
              <Line
                key={tick + idx}
                y1={2}
                y2={height - 2}
                x1={x(tick)}
                x2={x(tick)}
                strokeWidth={1}
                stroke={'rgba(0,0,0,0.2)'}
              />
            ))}

            {Array.from(
              { length: categoryOrder.length + 1 },
              (_, i) => i * 2,
            ).map((tick, idx) => (
              <Line
                key={tick + idx}
                x1={5}
                x2={width - 5}
                y1={y(tick)}
                y2={y(tick)}
                strokeWidth={1}
                stroke={'rgba(0,0,0,0.2)'}
              />
            ))}
            <Line
              y1={2}
              y2={height - 2}
              x1={x(1)}
              x2={x(1)}
              strokeWidth={5}
              stroke={'rgba(0,0,0,0.1)'}
            />
            {area ? (
              <AnimatedPath
                fill="rgba(235, 128, 63, 0.6)"
                d={area}
                animatedProps={animatedAreaProps}
              />
            ) : null}

            {line ? (
              <AnimatedPath
                fill="none"
                strokeWidth={2}
                stroke="rgba(235, 128, 63, 1)"
                d={line}
                animatedProps={animatedLineProps}
              />
            ) : null}
          </Svg>
        </View>
      </View>
      {isWeb && (
        <Button
          style={styles.downloadButton}
          onPress={handleDownload}
          icon="download"
          mode="elevated"
          loading={isDownloading}
        >
          {isDownloading
            ? 'Generování PDF...'
            : isDesktop
              ? 'Stáhnout PDF'
              : 'PDF'}
        </Button>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  axis: {
    flexBasis: '26.7%',
    textAlign: 'center',
    fontSize: 12,
  },
  icon: {
    width: 29,
    height: 29,
    marginBottom: 5.7,
    marginLeft: 4,
  },
  pdfHeading: {
    fontWeight: '500',
    position: 'absolute',
    color: theme.colors.primary,
  },
  downloadButton: {
    fontSize: 10,

    flexDirection: 'row',
    gap: 0,
    alignItems: 'center',
    position: 'absolute',
    top: 6,
    right: 6,
  },
});
